import React, { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Button,
  Center,
  Flex,
  Heading,
  Link as ChakraLink,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useSession } from 'next-auth/react';

import {
  BrandLogo,
  ClipboardCheckIcon,
  CommunicationSkillIcon,
  Icon,
} from '@arena-labs/strive2-ui';

import { CoachPortalLayout } from '../components/layout/layout';
import { SignInModal } from '../components/layout/signin-form';

export function Index() {
  const { data: session, status } = useSession();
  const router = useRouter();

  const roles = session?.user.profile.roles;
  const isLeader = roles?.includes('leader');
  const isCoach = roles?.includes('coach');
  const isLaunchpadGuide = roles?.includes('launchpad_guide');
  const isSingleRole = roles?.length === 1;

  useEffect(() => {
    if (isLeader && isSingleRole) {
      router.replace('/leader');
    } else if (isCoach && isSingleRole) {
      router.replace('/coach');
    } else if (isLaunchpadGuide && isSingleRole) {
      router.replace('/launchpad');
    }
  }, [router, isLeader, isCoach, isSingleRole, isLaunchpadGuide]);

  const signInModal = useDisclosure();

  return status === 'loading' ? (
    <Center h="full">
      <BrandLogo spin noText />
    </Center>
  ) : status === 'authenticated' ? (
    <Center h="full">
      <Flex direction="column" alignItems="center" gap="10">
        <Heading as="h1" fontSize="h1">
          Choose your dashboard
        </Heading>
        <Flex gap="6" justifyContent="space-around">
          <Link href="/coach" passHref legacyBehavior>
            <ChakraLink
              variant="outline"
              py="2"
              gap="2"
              _hover={{ color: 'brand.500' }}
            >
              <Icon as={CommunicationSkillIcon} />
              Coach
            </ChakraLink>
          </Link>
          <Link href="/leader" passHref legacyBehavior>
            <ChakraLink
              variant="outline"
              py="2"
              gap="2"
              _hover={{ color: 'brand.500' }}
            >
              <Icon as={ClipboardCheckIcon} /> Leader
            </ChakraLink>
          </Link>
          <Link href="/launchpad" passHref legacyBehavior>
            <ChakraLink
              variant="outline"
              py="2"
              gap="2"
              _hover={{ color: 'brand.500' }}
            >
              <Icon as={ClipboardCheckIcon} /> Launchpad
            </ChakraLink>
          </Link>
        </Flex>
      </Flex>
    </Center>
  ) : (
    <Center h="full">
      <VStack spacing="10" border="1px solid gray" p="10" borderRadius="card">
        <Heading as="h1" fontSize="h1">
          You are not logged in
        </Heading>
        <SignInModal {...signInModal} />
        <Button variant="solid" onClick={signInModal.onOpen}>
          Sign in
        </Button>
      </VStack>
    </Center>
  );
}

export default Index;

Index.disableAnalyticsPageLoad = true;

Index.getLayout = (page: React.ReactElement) => (
  <CoachPortalLayout>{page}</CoachPortalLayout>
);
